import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as Logo } from '../assets/marca-1a.svg';

export default () => {
	return (
		<View>
			<Logo />
		</View>
	);
};

const View = styled(motion.div)`
	position: fixed;
	left: 8vw;
	width: 20vw;
	height: 20vw;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		width: 40vw;
		height: 40vw;
	}
`;
