import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './index.css';
import * as c from './components';

export default () => {
	return (
		<View className='container'>
			<c.Slideshow />
			<c.Brand />
			<c.About />
		</View>
	);
};

const View = styled.div`
	position: relative;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 100px;
	background-color: black;
	transform-origin: center center;
`;
