import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { motion, useMotionValue } from 'framer-motion';
import { useEventListener } from '../hooks';

import { ReactComponent as Close } from '../assets/close.svg';
import { ReactComponent as CloseWhite } from '../assets/close-white.svg';
import { UserAgent } from '@quentin-sommer/react-useragent';

export default () => {
	const flagRef = useRef(false);
	const wrapperRef = useRef(null);
	const [state, setState] = useState(`initial`);
	const x = useMotionValue(0);
	const y = useMotionValue(0);

	useEffect(() => setTimeout(setState(`close`), 2000), []);

	useEffect(() => {
		flagRef.current = state === `open`;
	}, [state]);

	const onMouseMove = useCallback(({ clientX, clientY }) => {
		x.set(clientX - 30);
		y.set(clientY - 30);
	}, []);

	useEventListener('mousemove', onMouseMove, document);

	const toggle = () => {
		if (state === `close` || state === `hover`) {
			setState(`open`);
		} else {
			setState(`close`);
			wrapperRef.current.scrollTo(0, 0);
		}
	};

	const hover = () => {
		if (state !== `open`) {
			setState(`hover`);
		}
	};

	const hoverout = () => {
		if (state !== `open`) {
			setState(`hoverout`);
		}
	};

	return (
		<>
			<Page onHoverStart={hover} onHoverEnd={hoverout} animate={state} onTap={toggle} style={{ cursor: state === `open` ? `none` : `default` }}>
				<Title>Primeira Opção</Title>
				<Subtitle>Impossibilidades Concretas</Subtitle>
				<Wrapper ref={wrapperRef} style={{ display: state === `open` ? `block` : `none` }}>
					<Contact href='mailto:fasesergio@gmail.com'>Contato</Contact>
					<Text>
						Primeira Opção é a produtora de Sergio Santos, que atua há mais de 20 anos na área de projetos culturais, exercendo diversas atividades:
						pesquisa, desenvolvimento, museografia, montagem, produção, conservação, restauro e transportes de obras de arte.
						<br />
					</Text>
					<Text2>
						<strong style={{ marginTop: `5vw`, marginBottom: `.1vw`, display: `block` }}>Principais projetos</strong>
						<br />
						EntreMeadas
						<br />
						Sesc Vila Mariana, 2019
						<br />
						<br />
						ETNOS: A Face da Diversidade
						<br />
						[Farol Santander, 2019]
						<br />
						<br />
						1ª Mostra GIFE de Inovação Social
						<br />
						[CCSP, 2019]
						<br />
						<br />
						Kurt Klagsbrunn: Faces da cultura, retratos de um tempo
						<br />
						[FIESP, 2019]
						<br />
						<br />
						Showcase – SIDIA
						<br />
						[Manaus, 2019]
						<br />
						<br />
						A Cultura de um Povo, o desenvolvimento de um País
						<br />
						[exposição itinerante para Catepillar, 2019]
						<br />
						<br />
						Saramago
						<br />
						[Farol Santander, Porto Alegre, 2019]
						<br />
						<br />
						Museu da Natureza
						<br />
						[São Raimundo Nonato-PI, 2018]
						<br />
						<br />
						Ai Wei Wei
						<br />
						[Santiago e itinerância Brasil, 2018/2019]
						<br />
						<br />
						EX Africa [CCBB, 2018], Saramago
						<br />
						[Farol Santander-SP, 2018]
						<br />
						<br />
						Japan House
						<br />
						[um ano de projeto para consolidação do centro cultural]
						<br />
						<br />
						Ervin Wurm
						<br />
						[CCBB, todas as unidades, 2017]
						<br />
						<br />
						Patricia Pitinini
						<br />
						[CCBB, 2016]
						<br />
						<br />
						Oir Outras Ideias para o Rio
						<br />
						CICLO
						<br />
						[CCBB, 2014]
						<br />
						<br />
						Mais de 1000 Brinquedos
						<br />
						[SESC Pompéia, 2013]
						<br />
						<br />
						Cai Guo-Quiang
						<br />
						[CCBB, São Paulo, Rio De Janeiro e Brasília, 2013]
						<br />
						<br />
						Exposição Permanente Biblioteca Brasiliana Guita e José Mindlin
						<br />
						[USP-SP, 2013]
						<br />
						<br />
						Antony Gormley [CCBB, São Paulo, Rio de Janeiro e Brasília, 2012
						<br />
						Água na Oca
						<br />
						[Oca, São Paulo, 2010]
						<br />
						<br />
						Ascencion – Anish Kapoor
						<br />
						[CCBB, São Paulo, Brasília e Rio de Janeiro, 2006/2007]
						<br />
						<br />
						Museu do Homem Americano
						<br />
						[Fumdham, São RaimundoNonato-PI,2007]
						<br />
						<br />
						Os Guerreiros de Xi’an e os Tesouros da Cidade Proibida
						<br />
						[Oca, São Paulo, 2003]
						<br />
						<br />
						World Expo 2010 Shangai – Pavilhão de PortoAlegre
						<br />
						<br />
						[Shangai, China, 2010]
						<br />
						<br />
						Pelé Station – Experience the legend in action
						<br />
						[Berlim, Alemanha, 2006]
						<br />
						<br />
						Amazon Native – Arqueologia e Etnografia
						<br />
						[Pequim, China, 2003]
						<br />
						<br />
						Brazil Body Soul – BrasilConnects/Guggeneim
						<br />
						[Nova Iorque, USA, 2001]
						<br />
						<br />
						Território Virgem – The National Museum ofWoman in the Arts
						<br />
						[Washington, USA, 2001]
						<br />
						<br />
						Word Economic Forun 2003 – Exposição de Arte Brasileira
						<br />
						[Davos, Suíça, 2003].
					</Text2>
				</Wrapper>
				<Switcher animate={state === `open` ? `open` : `close`} variants={{ open: { rotate: 0 }, close: { rotate: 45 } }} onTap={toggle}>
					<CloseWhite width='150' height='150' />
				</Switcher>
			</Page>
			{state === `open` && (
				<MouseView style={{ x, y }}>
					<Close width='150' height='150' />
				</MouseView>
			)}
		</>
	);
};

const Page = ({ children, onHoverStart, onHoverEnd, animate, onTap, style }) => {
	return (
		<>
			<UserAgent computer>
				<View {...A} onHoverStart={onHoverStart} onHoverEnd={onHoverEnd} animate={animate} onTap={onTap} style={style}>
					{children}
				</View>
			</UserAgent>
			<UserAgent mobile>
				<View {...B} animate={animate} style={style}>
					{children}
				</View>
			</UserAgent>
		</>
	);
};

const A = {
	variants: {
		initial: { y: `100vh`, backgroundColor: `rgba(0,0,0,0)`, transition: { duration: 0 } },
		close: { y: `85vh`, backgroundColor: `rgba(0,0,0,0)` },
		hover: { y: `80vh`, backgroundColor: `rgba(0,0,0,1)` },
		hoverout: { y: `85vh`, backgroundColor: `rgba(0,0,0,0)` },
		open: { y: `0vh`, backgroundColor: `rgba(0,0,0,1)` },
	},
	transition: { damping: 70, stiffness: 1000 },
};

const B = {
	variants: {
		initial: { y: `100vh`, backgroundColor: `rgba(0,0,0,0)`, transition: { duration: 0 } },
		close: { y: `70vh`, backgroundColor: `rgba(0,0,0,0)` },
		open: { y: `0vh`, backgroundColor: `rgba(0,0,0,1)` },
	},
	transition: { damping: 70, stiffness: 1000 },
};

const View = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	padding: 0px 7vw 0 10vw;
	font-size: 1.563vw;
	color: white;
	width: 100vw;
	height: 100vh;
	background-color: black;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		padding: 0px 6vw 0 10vw;
	}
`;

const MouseView = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 150px;
	height: 150px;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	cursor: none;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		display: none;
	}
`;

const Wrapper = styled(motion.div)`
	width: 100%;
	height: 100%;
	overflow-y: scroll;
`;

const Title = styled.h1`
	position: absolute;
	margin-top: 3vw;
	font-size: 1vw;
	font-family: basier-bold;
	color: white;
	user-select: none;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		margin-top: 10vw;
		font-size: 4.5vw;
	}
`;

const Subtitle = styled.h2`
	position: absolute;
	margin-top: 4.5vw;
	font-size: 1vw;
	font-family: basier-medium;
	color: #ccc;
	user-select: none;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		font-size: 4.5vw;
		margin-top: 16.25vw;
	}
`;

const Contact = styled.a`
	position: absolute;
	top: 3vw;
	right: 10vw;
	font-size: 1vw;
	font-family: basier-medium;
	// margin-top: 0.26vw;
	color: white;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		position: relative;
		font-size: 3.5vw;
		margin-top: 19vh;
		left: 0vw;
		background-color: #222;
		width: 20vw;
		padding: 3vh 8vh;

		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 10vh;
	}
`;

const Text = styled.p`
	margin-top: 20vh;
	margin-bottom: 10vw;
	line-height: 1.1;
	font-size: 5.85vw;
	font-family: basier-bold;
	color: #ddd;
	user-select: none;

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		font-size: 6vw;
		line-height: 1.2;
	}
`;

const Text2 = styled.p`
	margin-top: 20vw;
	margin-bottom: 5vw;
	font-size: 1.6vw;
	font-family: basier-medium;
	line-height: 1.5;
	color: grey;
	user-select: none;

	strong {
		color: white;
		font-family: basier-bold;
	}

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		font-size: 4vw;
		line-height: 1.2;
	}
`;

const Switcher = styled(motion.div)`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 7vw;
	top: 6vh;
	width: 10vw;
	height: 10vw;

	@media only screen and (min-width: 767px) {
		display: none;
	}
`;
