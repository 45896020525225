import React from 'react';
import ReactDOM from 'react-dom';
import App from './application';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import './index.css';

// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<React.StrictMode>
		<UserAgentProvider ua={window.navigator.userAgent}>
			<App />
		</UserAgentProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// serviceWorker.unregister();
